<template>
	<div class="homepage">
		<audio ref="getVoice">
			<source src="../assets/voice/voice.mp3" type="audio/mpeg">
		</audio>
		<!-- logo -->
		<!-- <div class="logo">
      <img :src="mobileLogoUrl" alt="" class="logo-img" />
      <div class="langBox dp-flex-row flex-ai-center">
        <img :src="require('../assets/images/'+selLanguage.icon)" alt="">
        <el-dropdown trigger="click" class="selLang" @command="changeLaguages">
          <span class="el-dropdown-link">
            {{selLanguage.label}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in langOptions" :key="item.value" :command="item">{{item.label}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div> -->
		<!-- tabbar -->
		<div class="tabBar">
			<el-dropdown trigger="click" class="selLang" @command="changeJhe">
				<span class="el-dropdown-link">
					<i class="el-icon-s-unfold" size='40'></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-for="item in jiheList" :key="item.value" :command="item">{{item.label}}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<!-- <div>
				<span class="click">{{ $t("lang.VIPquery") }}</span>
			</div>
			<div v-if="footData.home_url">
				<a :href="footData.home_url"> {{ $t("lang.officialIndex") }}</a>
			</div> -->
			<img class="img-logo" :src="pcLogoUrl" alt="">
			<el-dropdown trigger="click" class="tr selLang" @command="changeLaguages">
				<span class="el-dropdown-link">
					{{selLanguage.label}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-for="item in langOptions" :key="item.value" :command="item">{{item.label}}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<!-- <div v-if="footData.activity_url">
          <a :href="footData.activity_url"> {{ $t("lang.specialOffer") }}</a>
        </div> -->
			<!-- <div v-if="footData.cs_url">
          <a :href="footData.cs_url">{{ $t("lang.onlineCustomerService") }}</a>
        </div>
        <div v-if="footData.line_cs_url" id="positonBox">
          <a :href="footData.line_cs_url">{{ $t("lang.Line") }}
          </a>
        </div> -->
		</div>
		<!--大图 -->
		<div class="bigPic">
			<div class="banner-info">
				<h2>{{ $t("lang.bannerTitle") }}</h2>
				<div>
					<p>{{ $t("lang.bannerInfo01") }}</p>
					<p>{{ $t("lang.bannerInfo02") }}</p>
				</div>
			</div>
		<!-- 	<img v-if="templayteName!=''"
				:src="require('../assets/mobileimages/'+templayteName+'/banner_'+nowLang+'.png')" alt=""> -->
			<!-- <div class="img-prize " :class="'img-prize_'+nowLang">
        <div class="wrap">
          <p class="dp-flex-row flex-ai-center prize-title">
            <span>{{ $t("lang.Game") }}</span>
            <span>{{ $t("lang.Member") }}</span>
            <span>{{ $t("lang.Bonus") }}</span>
          </p>
          <div id="review_box" class="review_box">
            <ul id="comment1">
              <li v-for="(item,index) in gameData" :key="index" class="dp-flex-row flex-ai-center">
                <span>{{item[2]}}</span>
                <span>{{item[0]}}</span>
                <span>{{item[3]}}{{item[1]}}</span>
              </li>
            </ul>
            <ul id="comment2"></ul>
          </div>
        </div>
      </div> -->
			<!-- <div class="img-prize2" :class="'img-prize2_'+nowLang">
        <div class="wrap">
          <p class="dp-flex-row flex-ai-center prize-title">
            <span>{{ $t("lang.Member") }}</span>
            <span style="width:40%">{{ $t("lang.Name") }}</span>
            <span style="width:20%">{{ $t("lang.Bonus") }}</span>
          </p>
          <div id="review_box2" class="review_box">
            <ul id="ccomment1">
              <li v-for="(item,index) in menberData" :key="index" class="dp-flex-row flex-ai-center">
                <span>{{item[0]}}</span>
                <span style="width:40%">{{item[2]}}</span>
                <span style="width:20%">{{item[3]}}{{item[1]}}</span>
              </li>
            </ul>
            <ul id="ccomment2"></ul>
          </div>
        </div>
      </div> -->
		</div>
		<!-- search -->
		<div class="search">
			<div class="searchInput">
				<div class="text">{{ $t("lang.VIPLevelQuery") }}</div>
				<input type="text" :placeholder="$t('lang.PleaseInputAccount')" v-model="username" />
				<span @click="search()">{{ $t("lang.Query") }}</span>
			</div>
		</div>
		<div class="list-content">
			<div class="img-prize " :class="'img-prize_'+nowLang">
				<div class="wrap">
					<p class="dp-flex-row flex-ai-center prize-title">
						<span>{{ $t("lang.Game") }}</span>
						<span>{{ $t("lang.Member") }}</span>
						<span>{{ $t("lang.Bonus") }}</span>
					</p>
					<div id="review_box" class="review_box">
						<ul id="comment1">
							<li v-for="(item,index) in gameData" :key="index" class="dp-flex-row flex-ai-center">
								<span>{{item[2]}}</span>
								<span>{{item[0]}}</span>
								<span>{{item[3]}}{{item[1]}}</span>
							</li>
						</ul>
						<ul id="comment2"></ul>
					</div>
				</div>
			</div>
			<div class="img-prize2" :class="'img-prize2_'+nowLang">
				<div class="wrap">
					<p class="dp-flex-row flex-ai-center prize-title">
						<span>{{ $t("lang.Member") }}</span>
						<span style="width:40%">{{ $t("lang.Name") }}</span>
						<span style="width:20%">{{ $t("lang.Bonus") }}</span>
					</p>
					<div id="review_box2" class="review_box">
						<ul id="ccomment1">
							<li v-for="(item,index) in menberData" :key="index" class="dp-flex-row flex-ai-center">
								<span>{{item[0]}}</span>
								<span style="width:40%">{{item[2]}}</span>
								<span style="width:20%">{{item[3]}}{{item[1]}}</span>
							</li>
						</ul>
						<ul id="ccomment2"></ul>
					</div>
				</div>
			</div>
		</div>
		<!-- tabbar2 -->
		<div class="changeStyle dp-flex-row flex-ai-center">
			<div :class="activeIndex == index ? 'active' : ''" @click="changestyle(index)" v-for="(item, index) in list"
				:key="index">
				<p>{{ item.name }}</p>
			</div>
		</div>
		<div class="itemInfo">
			<div :class="activeIndex == 0 ? '' : 'none'">
				<!-- <div class="content">
					{{ $t("lang.MemberContent", { msg: customBeginDate}) }}
				</div> -->
				<div class="content">
					{{ $t("lang.vip_level", { msg: customBeginDate}) }}
				</div>
				<p class="tip" style="margin: 10px 10px;"> {{ $t("lang.vip_exam") }}</p>


				<div class="tableFather" ref="tableBoxFather">
					<div class="tableBox" ref="tableBox">
						<!-- <div class="tableTh" border="0">
							<div>{{ $t("lang.VIPLevel") }}</div>
							<div>{{ $t("lang.CumulativeEffectiveBetting") }}</div>
							<div>{{ $t("lang.PromotionFiled") }}</div>
							<div>{{ $t("lang.HolidayFiled") }}</div>
							<div>{{ $t("lang.BirthdayHandsel") }}</div>
							<div>{{ $t("lang.LuckyMoney") }}</div>
							<div>{{ $t("lang.NegativeGold") }}</div>
							<div>{{ $t("lang.ExclusiveDomainName") }}</div>
							<div>{{ $t("lang.DedicatedService") }}</div>
						</div> -->
						<div class="tableTh" border="0">
							<div v-for="item in vipTh" :key="item">{{item}}</div>
						</div>
						<div class="tableTd" v-if="nowLang !== 'usdt'" v-for="(item, index) in dataList1" :key="index">
							<div>{{ item.name }}</div>
							<div>{{ item.a_drop_trans }}</div>
							<!-- <div v-if="nowLang=='en'">{{ item.a_drop }}</div> -->
							<div>{{ item.promotion }}</div>
							<div>
								<span v-if="parseInt(item.festival_bonus)>0">{{ item.festival_bonus }}</span>
								<span v-else class="status incorrect"></span>
							</div>
							<div>
								<span v-if="parseInt(item.birthday_bonus)>0">{{ item.birthday_bonus }}</span>
								<span v-else class="status incorrect"></span>
							</div>
							<!-- <div>{{ item.month_save_promotion }}</div> -->
							<div>{{ item.lucky_bonus }}</div>
							<div v-if="selLanguage.value!='id'">{{ item.negative_bonus }}</div>
							<!-- <div v-if="nowLang!='yn'">
								<span v-if="item.line == 1" class="status correct"></span>
								<span v-if="item.line == 0" class="status incorrect"></span>
							</div> -->
							<div>
								<span v-if="item.service == 1" class="status correct"></span>
								<span v-if="item.service == 0" class="status incorrect"></span>
							</div>
						</div>
						
						<div class="tableTd" v-if="nowLang == 'usdt'" v-for="(item, index) in usdtAdvantages" :key="index">
							<div>{{ item.name }}</div>
							<div>{{ item.a_keep }}</div>
							<div>{{ item.promotion }}</div>
							<div>{{ item.lucky_bonus }}</div>
							<div>{{ item.birthday_bonus }}</div>
							<div>{{ item.festival_bonus }}</div>
							<div>{{ item.negative_bonus }}</div>
							<div>
								<span v-if="item.service == 1" class="status correct"></span>
								<span v-if="item.service == 0" class="status incorrect"></span>
							</div>
						</div>
					</div>
				</div>

				<div class="redText" style="margin-top: 10px">
					{{ $t("lang.tipBottom") }}
				</div>
				<!-- <p class="tip" style="margin-top: 10px">
          {{ $t("lang.vip_level_tip") }}
        </p> -->
				<p class="tip" v-if="templayteName != 'U31'">
					{{ $t("lang.tipBottom02") }}
				</p>
				<p class="tip" v-if="templayteName == 'U31'">
					{{ $t("lang.vip_level_tip1") }}
				</p>
				<!-- <p  class="tip"  style="margin-top: 10px">
          {{ $t("lang.tipBottom") }}
        </p> -->
			</div>
			<!-- <div :class="activeIndex == 1 ? '' : 'none'">
        <div class="handselBox">
          <div class="handsel1">{{ $t("lang.HowApplyLuckyMoney") }}</div>
          <div class="handsel2">
            <p>
              {{ $t("lang.LuckyContent1") }}
            </p>
          </div>
          <div class="handsel3">
            {{ $t("lang.LuckyContent2") }}
          </div>
          <div class="handsel4">
            {{ $t("lang.LuckyContent3") }}
          </div>
        </div>
        <div class="headselTable">
          <div class="headseltableBox" border="0">
            <div class="headseltableTh">
              <div>{{ $t("lang.LuckyLevel") }}</div>
              <div style="width: 134px">{{ $t("lang.MonthlyDeposit") }}</div>
              <div>{{ $t("lang.DowryGoodLuck") }}</div>
            </div>
            <div class="headseltableTd" v-for="(item, index) in dataList2" :key="index">
              <div>{{ item.name }}</div>
              <div>{{ item.m_drop_trans }}</div>
              <div>{{ item.lucky_bonus }}</div>
            </div>
          </div>
          <table></table>
        </div>
      </div> -->
			<!-- <div :class="activeIndex == 2 ? '' : 'none'">
        <div class="giftBox">
          <div class="giftTit">
            {{ $t("lang.HowApplyMonthlyDepositDiscount") }}
          </div>
          <div class="giftCon">
            {{ $t("lang.LavishContent") }}
          </div>
          <div class="giftTable" border="0">
            <div class="giftTableTh">
              <div>{{ $t("lang.Lavishlevel") }}</div>
              <div style="width: 62px">{{ $t("lang.softLoan") }}</div>
              <div style="width: 67px">{{ $t("lang.NumberRequested") }}</div>
              <div style="width: 88px">{{ $t("lang.OfferOnline") }}</div>
              <div style="width: 70px">{{ $t("lang.withdrawals") }}</div>
            </div>
            <div class="giftTableTd" v-for="(item, index) in dataList3" :key="index">
              <div>{{ item.name }}</div>
              <div>{{ item.month_save_promotion }}</div>
              <div>{{ item.apply_count }}</div>
              <div>{{ item.promotion_limit }}</div>
              <div v-if="nowLang=='zh'">
                {{ item.withdraw_limit }}{{ $t("lang.double") }}
              </div>
              <div v-if="nowLang=='th'||nowLang=='en'">
                ×{{ item.withdraw_limit }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
			<div :class="activeIndex == 1 ? '' : 'none'">
				
				<div v-if="nowLang == 'usdt'">
					<div class="handselBox">
						<div class="handsel1">วีธีการสมัครโบนัสนำโชค?</div>
						<div class="handsel2">ระบบจะแจกโบนัสนำโชคอัตโนมัติโดยไม่ต้องทำการสมัครอย่างต่อเนื่องหลังเวลา 04.00น. (GMT+8) ในวันที่ 5 ของทุกเดือนตามเวลา เนื่องจากโบนัสมีจำนวนมาก อาจมีความล่าช้าในการทำรายการ โปรดทำการรอหากไม่ได้รับในเวลา</div>
						<div class="handsel2">สมาชิกต้องอยู่ในระดับVIPและจำนวนเงินฝากที่กำหนดก่อน ถึงจะได้รับโบนัสนำโชคที่ที่กำหนดไว้ค่ะ</div>
						<div class="handsel2">ตัวอย่าง สมาชิก A อยู่ระดับ VIP5 ต้องมียอดฝากภายในเดือน 3000 USDT แต่ สมาชิก A มียอดฝากเพียง 1500  USDTไม่ถึงขั้นต่ำที่กำหนด  ดังนั้นจะได้รับตามระดับยอดฝาก VIP4 ได้รับ58USDT</div>
						<div class="headselTable">
							<div class="headseltableBox" border="0">
								<div class="headseltableTh">
									<div>ระดับ</div>
									<div style="width: 134px">ยอดฝากประจำเดือน</div>
									<div>จำนวนโบนัส</div>
								</div>
								<div class="headseltableTd" v-for="(item, index) in usdtAdvantages" :key="index">
									<div>{{ item.name }}</div>
									<div>{{ item.m_drop }}</div>
									<div>{{ item.lucky_bonus }}</div>
								</div>
							</div>
							<table></table>
						</div>
						<div class="handsel1">วีธีการสมัครโบนัสอัพเกรด?</div>
						<div class="handsel2">หากยอดฝากถึงที่กำหนด ระบบจัดส่งโบนัสอัพเกรดVIPให้โดยอัตโนมัติในวันถัดไป เวลาจัดส่ง 02.00 GMT+8</div>
						<div class="handsel2">หากมีคำถามเพิ่มเติมกรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการของเรา ขอบคุณค่ะ</div>
						<div class="handsel1">โบนัสยอดเสียประจำเดือน?</div>
						<div class="handsel2">เพียงสมาชิกภายในเดือนมียอดเสีย100USDTขึ้นไป/เดือนและอยู่ในระดับวีไอพีที่เข้าร่วมจะได้รับโบนัสช่วยเหลือเพิ่มดวงจากทางเราสูงสุด 5% ระบบจะแจกโบนัสอัตโนมัติในวันที่ 15 ของทุกเดือน ไม่จำกัดจำนวนโบนัส</div>
						<div class="handsel1">วีธีการสมัครโบนัสวันเกิด?</div>
						<div class="handsel2">ติดต่อฝ่ายบริการลูกค้าออนไลน์เพื่อยืนยันหลักฐาน หลังจากที่ตรวจสอบเรียบร้อยเจ้าหน้าที่จะส่งโบนัสให้คุณในวันเกิดของคุณ</div>
						<div class="handsel1">(เพื่อความสะดวกในการดำเนินรายการ กรุณาติดต่อเจ้าหน้าที่เพื่อกรอกข้อมูลวันเกิดให้ครบถ้วน)</div>
						<div class="handsel1">วิธีการสมัครโบนัสเทศกาล?</div>
						<div class="handsel2">หลักเกณฑ์การแจก：ต้องมียอดฝาก 200 ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส</div>
						<div class="handsel1">เกี่ยวกับการให้บริการตลอด 24 ชั่วโมง</div>
						<div class="handsel2">หากท่านพบปัญหาเกี่ยวกับ การฝาก-ถอน หรือมีข้อสงสัยสามารถติดต่อเจ้าที่ได้ตลอด เรามีเจ้าหน้าที่ให้บริการตลอด 24 ชั่วโมง</div>
						<div class="handsel2" style="margin-top: 10px; text-align: center;">ข้อความข้างต้นเป็นข้อกำหนดและเงื่อนไขทั่วไป ของ แพลตฟอร์ม</div>
					</div>
				</div>
				
				
				<div v-if="nowLang !== 'usdt'">
					<div class="handselBox">
						<div class="handsel1">{{ $t("lang.HowApplyLuckyMoney") }}</div>
						<div class="handsel2">
							<p v-if="templayteName != 'U31'">
								{{ $t("lang.LuckyContent1") }}
							</p>
							<p v-if="templayteName == 'U31'">
								{{ $t("lang.LuckyContent1_u") }}
							</p>
						</div>
						<div class="handsel3">
							{{ $t("lang.LuckyContent2") }}
						</div>
						<div class="handsel4">
							<!-- {{ $t("lang.LuckyContent3") }} -->
							{{ $t("lang.tab1_p4") }}
						</div>
					</div>
					<div class="headselTable">
						<div class="headseltableBox" border="0">
							<div class="headseltableTh">
								<div>{{ $t("lang.LuckyLevel") }}</div>
								<div style="width: 134px">{{ $t("lang.MonthlyDeposit") }}</div>
								<div>{{ $t("lang.DowryGoodLuck") }}</div>
							</div>
							<div class="headseltableTd" v-for="(item, index) in dataList2" :key="index">
								<div>{{ item.name }}</div>
								<div>{{ item.m_drop_trans }}</div>
								<!-- <div v-if="nowLang=='en'">{{ item.m_drop }}</div> -->
								<div>{{ item.lucky_bonus }}</div>
							</div>
						</div>
						<table></table>
					</div>
					<div class="privilegeBox">
						<div class="privilegeTit">
							{{ $t("lang.tab3_p1") }}
						</div>
						<div class="privilegeCon" v-if="templayteName != 'U31'">
							{{ $t("lang.tab3_p2") }}
						</div>
						<div class="privilegeCon" v-if="templayteName == 'U31'">
							{{ $t("lang.tab3_p15") }}
						</div>
						<div class="privilegeTit" v-if="selLanguage.value!='id'">
							{{ $t("lang.tab3_p3") }}
						</div>
						<div class="privilegeCon" v-if="selLanguage.value!='id'&&templayteName != 'U31'">
							{{ $t("lang.tab3_p4") }}
						</div>
						<div class="privilegeCon" v-if="selLanguage.value!='id'&&templayteName == 'U31'">
							{{ $t("lang.tab3_p16") }}
						</div>
						<div class="privilegeTit">{{ $t("lang.tab3_p5") }}</div>
						<div class="privilegeCon">
							<p>{{ $t("lang.tab3_p6") }}</p>
							<p>{{ $t("lang.tab3_p7") }}</p>
						</div>
						<div class="privilegeTit">{{ $t("lang.tab3_p8") }}</div>
					
						<div class="privilegeCon" v-if="templayteName != 'U31'">
							{{ $t("lang.tab3_p9") }}
						</div>
						<div class="privilegeCon" v-if="templayteName == 'U31'">
							{{ $t("lang.tab3_p9_u") }}
						</div>
						<div class="privilegeTit">{{ $t("lang.tab3_p10") }}</div>
						<div class="privilegeCon">
							{{ $t("lang.tab3_p11") }}
						</div>
						<div class="privilegeTit">{{ $t("lang.tab3_p12") }}</div>
						<div class="privilegeCon">
							{{ $t("lang.tab3_p13") }}
						</div>
						
						<p class="tip" style="margin-top: 10px;">{{ $t("lang.tipBottom03") }}</p>
						<p class="tip" style="margin-top: 10px;" v-if="nowLang=='en'">{{ $t("lang.tipBottom04") }}</p>
						<!-- <p class="tip" v-if="nowLang!='yn'">{{ $t("lang.tab3_p14") }}</p> -->
						<p class="tip">{{ $t("lang.tipBottom02") }}</p>
				</div>
			
				</div>
				<!-- <div class="privilegeBox">
					<div class="privilegeTit">
						{{ $t("lang.tab3_p1") }}
					</div>
					<div class="privilegeCon" v-if="templayteName != 'U31'">
						{{ $t("lang.tab3_p2") }}
					</div>
					<div class="privilegeCon" v-if="templayteName == 'U31'">
						{{ $t("lang.tab3_p15") }}
					</div>
					<div class="privilegeTit">
						{{ $t("lang.HowMonthlyNegativeInterestTranshipmentPayment") }}
					</div>
					<div class="privilegeCon" v-if="templayteName != 'U31'">
						{{ $t("lang.PrivilegeContent1") }}
					</div>
					<div class="privilegeCon" v-if="templayteName == 'U31'">
						{{ $t("lang.tab3_p16") }}
					</div>
					<div class="privilegeTit">{{ $t("lang.PrivilegeContent2") }}</div>
					<div class="privilegeCon">
						{{ $t("lang.PrivilegeContent3") }}
						<span>{{ $t("lang.PrivilegeContent4") }}</span>
					</div>
					<div class="privilegeTit">{{ $t("lang.PrivilegeContent5") }}</div>

					<div class="privilegeCon" v-if="templayteName != 'U31'">
						{{ $t("lang.PrivilegeContent6") }}
					</div>
					<div class="privilegeCon" v-if="templayteName == 'U31'">
						{{ $t("lang.PrivilegeContent6_u") }}
					</div>
					<div class="privilegeTit">{{ $t("lang.PrivilegeContent7") }}</div>
					<div class="privilegeCon">
						{{ $t("lang.PrivilegeContent8") }}
					</div>
					<div class="privilegeTit">{{ $t("lang.PrivilegeContent9") }}</div>
					<div class="privilegeCon">
						{{ $t("lang.PrivilegeContent10") }}
					</div>
					<div class="privilegeCon" style="text-align: center">
						{{ $t("lang.PrivilegeContent11") }}
					</div>
					<p class="tip" style="margin-top: 10px;    text-align: center;">{{ $t("lang.tipBottom") }}</p>
				</div>
			 -->
			</div>
		</div>

		<!-- 弹窗 -->
		<div class="cover" v-if="isQueryDia"></div>
		<div class="dialog-query" v-if="isQueryDia">
			<div class="dia-top">
				<span class="t-queryresult">{{ $t("lang.QueryResult") }}</span>
				<img src="../assets/mobileimages/btn_close.png" alt="" @click="isQueryDia = false">
				<!-- <span class="status incorrect" @click="isQueryDia = false"></span> -->
			</div>
			<div class="dialog-main" v-if="searchStatus">
				<div class="dialog-top">
					<div>
						{{ $t("lang.Exalted") }}<span>{{ searchdata.user_name }}</span>
					</div>
					<div>
						{{ $t("lang.YourCurrentVIPLevel")
            }}<span v-if="searchdata.vip_lv=='Normal'&&selLanguage.value=='zh'">{{ $t("lang.normal") }}</span><span
							v-else>{{ searchdata.vip_lv }}</span>
					</div>
					<div v-if="searchdata.standard == 4||searchdata.standard == 5">
						{{ $t("lang.CurrentActiveBets")
            }}<span>{{ searchdata.bet_sum}}</span>
					</div>
					<div v-if="searchdata.standard == 2||searchdata.standard == 5 ">
						{{ $t("lang.CurrentActBets") }}<span>{{ searchdata.save_sum }}</span>
					</div>
				</div>
				<div class="dialog-vip">
					<span v-if="nowLang=='th'" class="box">{{ $t("lang.privilege") }}{{ searchdata.vip_lv }}</span>
					<span v-if="nowLang=='zh'||nowLang=='en'" class="box"><span
							v-if="searchdata.vip_lv=='Normal'&&selLanguage.value=='zh'">{{ $t("lang.normal") }}</span><span
							v-else>{{ searchdata.vip_lv }}</span>{{ $t("lang.privilege") }}</span>

				</div>
				<!-- <div class="get-wrap dp-flex-row flex-js-center">
          <button class="btn-get" :disabled="!searchdata.promotion_list||searchdata.promotion_list.length<=0" :class="searchdata.promotion_list&&searchdata.promotion_list.length>0?'':'noget'" @click="openGetPrizeDialog">{{ $t("lang.click_collection") }}</button>
        </div> -->

				<div class="dialog-list">
					<div class="dialog-item">
						<div>{{ $t("lang.PromotionFiled") }}</div>
						<div>{{ searchdata.promotion }}</div>
					</div>
					<div class="dialog-item">
						<div>{{ $t("lang.LuckyMoney") }}</div>
						<div>{{ searchdata.lucky_bonus }}</div>
					</div>
					<div class="dialog-item">
						<div>{{ $t("lang.Birthday") }}</div>
						<div>{{ searchdata.birthday_bonus }}</div>
					</div>
					<div class="dialog-item">
						<div>{{ $t("lang.Holiday") }}</div>
						<div>{{ searchdata.festival_bonus }}</div>
					</div>
					<div class="dialog-item">
						<div>{{ $t("lang.Negative") }}</div>
						<div>{{ searchdata.negative_bonus }}</div>
					</div>
					<!-- <div class="dialog-item">
            <div>{{ $t("lang.Monthly") }}</div>
            <div>{{ searchdata.month_save_promotion }}</div>
          </div> -->
					<!-- <div class="dialog-item" v-if="nowLang!='yn'">
						<div>{{ $t("lang.exclusiveLine") }}</div>
						<div v-if="searchdata.line == 1" class="status correct"></div>
						<div v-if="searchdata.line == 0" class="status incorrect"></div>
					</div> -->
					<div class="dialog-item">
						<div>{{ $t("lang.exclusiveService") }}</div>
						<div v-if="searchdata.service == 1" class="status correct"></div>
						<div v-if="searchdata.service == 0" class="status incorrect"></div>
					</div>
				</div>
				<div class="dialog-text">
					<div v-if="searchdata.standard == 4||searchdata.standard == 5">
						{{ $t("lang.BetsAreNeededQualify") }}:{{ searchdata.next_need_bet }}
					</div>
					<div v-if="searchdata.standard == 2||searchdata.standard == 5">
						{{ $t("lang.next_need_save") }}:{{ searchdata.next_need_save }}
					</div>
					<div>
						{{ $t("lang.VIPLevelAfterPromotion") }}:{{ searchdata.next_vip_lv }}
					</div>
				</div>
			</div>
			<div v-if="!searchStatus" style="text-align: center;padding:30px 20px;font-size:14px">
				{{ $t("lang.noData") }}
			</div>
			<!-- <div class="dislog-close">
        <span @click="isQueryDia = false">{{ $t("lang.Close") }}</span>
      </div> -->
			<div class="dialog-cover" v-if="prizeDialog">
			</div>
			<div class="dialog-get dp-flex-column flex-ai-center" v-if="prizeDialog">
				<p class="p1">{{ $t("lang.get_title") }}</p>
				<div v-if="isGetPrize">
					<p class="p2 dp-flex-row flex-ai-center" v-for="(item,index) in searchdata.promotion_list"
						:key="index">{{item.typeName}}：<span class="money">{{item.money}}</span></p>
				</div>
				<div v-else>
					<p>{{ $t("lang.get_fail") }}</p>
				</div>
				<div @click="closePrizeDialog" class="btn-close">{{ $t("lang.concern") }}</div>
			</div>
		</div>

		<!-- <p class="tip" style="margin-top: 10px;    text-align: center;"> {{ $t("lang.tipBottom") }}</p> -->
		<div class="footer">
			<span> Copyright &copy;{{ footData.site_copy }}Reserved</span>
			<!-- <span> Copyright &copy;wg88 Reserved</span> -->
		</div>
	</div>
</template>

<script>
	import {
		gameDatas,
		menberDatas
	} from "../../public/static/params";
	export default {
		name: "HomePage",
		components: {},
		data() {
			return {
				vipTh: [],
				pcLogoUrl: "",
				gameData: gameDatas,
				menberData: menberDatas,
				isGetPrize: false,
				prizeDialog: false,
				nowLang: "th",
				selLanguage: {},
				activeIndex: 0,
				isQueryDia: false,
				mobileLogoUrl: "",
				customBeginDate: "",
				templayteName: "",
				// topImg: require("../assets/mobileimages/banner_th.png"),
				list: [],
				searchdata: [],
				username: "",
				siteName: "",
				dataList1: [],
				dataList2: [],
				dataList3: [],
				footData: [],
				usdtAdvantages:[],
				searchStatus: false,
				// langOptions: [{
				// 		value: "zh",
				// 		label: "中文",
				// 		icon: "flag_china.png"
				// 	},
				// 	{
				// 		value: "th",
				// 		label: "ไทย",
				// 		icon: "flag_thai.png"
				// 	},
				// 	{
				// 		value: "en",
				// 		label: "English",
				// 		icon: "flag_america.jpg"
				// 	},
				// 	{
				// 		value: "yn",
				// 		label: "Việt Nam",
				// 		icon: "flag_Vietnamese.png"
				// 	},
				// 	{
				// 		value: "id",
				// 		label: "Indonesia",
				// 		icon: "flag_Indonesia.png"
				// 	}
				// ],
				langOptions: [
					{
						value: "th",
						label: "ไทย",
						icon: "flag_thai.png"
					},
					{
						value: "en",
						label: "English",
						icon: "flag_america.jpg"
					},
					{
						value: "yn",
						label: "Việt Nam",
						icon: "flag_Vietnamese.png"
					},
					{
						value: "id",
						label: "Indonesia",
						icon: "flag_Indonesia.png"
					},
					{
						value: "usdt",
						label: "USDT",
						icon: "flag_america.jpg"
					}
				],
				promotionType: [],
				lang: "",
				whatsapp: [],
				n: null,
				whatsapp_cs_url: "",
			};
		},
		computed: {
			jiheList() {
				let list = []
				list.push({
					value: "",
					label: this.$t("lang.VIPquery"),
				}, )
				this.footData.home_url && list.push({
					value: this.footData.home_url,
					label: this.$t("lang.officialIndex"),
				}, )
				this.footData.activity_url && list.push({
					value: this.footData.activity_url,
					label: this.$t("lang.specialOffer"),
				}, )
				this.footData.cs_url && list.push({
					value: this.footData.cs_url,
					label: this.$t("lang.onlineCustomerService"),
				}, )
				// if(this.lang=="id") {
				// 	this.whatsapp_cs_url && list.push({
				// 		value: this.whatsapp_cs_url,
				// 		label: "whatsapp",
				// 	}, )
				// 	this.footData.line_cs_url && list.push({
				// 		value: this.footData.line_cs_url,
				// 		label: this.$t("lang.Line"),
				// 	}, )
				// } else{
				// 	this.footData.line_cs_url && list.push({
				// 		value: this.footData.line_cs_url,
				// 		label: this.$t("lang.Line"),
				// 	}, )
				// 	this.whatsapp_cs_url && list.push({
				// 		value: this.whatsapp_cs_url,
				// 		label: "whatsapp",
				// 	}, )
				// }
				// console.log(this.lang)
				
				return list
			}
		},
		created() {
			let queryLang = this.$route.query.lang;
			let lang = localStorage.getItem("language") || "th";
			// if (queryLang == "zh" || queryLang == "th" || queryLang == "en" || queryLang == "yn") {
			// 	lang = queryLang;
			// }
			if (queryLang == "th" || queryLang == "en" || queryLang == "id") {
				lang = queryLang;
			}
			this.nowLang = lang;
			this.$i18n.locale = lang;
			this.lang = lang
			this.vipTh = this.$t("lang.vipTh");
			this.list = [{
					name: this.$t("lang.MemberAdvantage"),
					index: 0
				},
				// { name: this.$t("lang.LuckyMoneyTit"), index: 1 },
				// { name: this.$t("lang.LavishGifts"), index: 2 },
				{
					name: this.$t("lang.PrivilegeThat"),
					index: 1
				}
			];
			this.gameData = this.gameData[this.nowLang];
			this.menberData = this.menberData[this.nowLang];
			this.selLanguage = this.langOptions.find(item => {
				return item.value == this.$i18n.locale;
			});
			// if (lang == "th") {
			//   this.topImg = require("../assets/mobileimages/banner_th.png");
			// } else if (lang == "zh") {
			//   this.topImg = require("../assets/mobileimages/banner_zh.png");
			// }else if (lang == "yn") {
			//   this.topImg = require("../assets/mobileimages/banner_yn.png");
			// } else if (lang == "en") {
			//   this.topImg = require("../assets/mobileimages/banner_en.png");
			// }
			this.promotionType = this.$t("lang.promotionType");
			this.getList1();
			this.getList2();
			this.getList3();
			this.getList4();
			this.getWebInfo();
		},
		mounted() {
			let dom1 = {
				comment1: "comment1",
				comment2: "comment2",
				reviewBox: "review_box"
			};
			let dom2 = {
				comment1: "ccomment1",
				comment2: "ccomment2",
				reviewBox: "review_box2"
			};
			this.roll(50, dom1, this.timer);
			this.roll(50, dom2, this.timer2);
		},
		methods: {
			roll(t, dom, timer) {
				var ul1 = document.getElementById(dom.comment1);
				var ul2 = document.getElementById(dom.comment2);
				var ulbox = document.getElementById(dom.reviewBox);
				ul2.innerHTML = ul1.innerHTML;
				ulbox.scrollTop = 0; // 开始无滚动时设为0
				clearInterval(timer);
				timer = setInterval(() => {
					if (ulbox.scrollTop >= ul1.scrollHeight) {
						ulbox.scrollTop = 0;
					} else {
						ulbox.scrollTop++;
					}
				}, t); // 设置定时器，参数t用在这为间隔时间（单位毫秒），参数t越小，滚动速度越快
				// 鼠标移入div时暂停滚动
				ulbox.onmouseenter = function() {
					clearInterval(timer);
				};
				// 鼠标移出div后继续滚动
				ulbox.onmouseleave = function() {
					timer = setInterval(() => {
						if (ulbox.scrollTop >= ul1.scrollHeight) {
							ulbox.scrollTop = 0;
						} else {
							ulbox.scrollTop++;
						}
					}, t);
				};
			},
			closePrizeDialog() {
				this.search(this.searchdata.user_name);
				this.prizeDialog = false;
			},
			openGetPrizeDialog() {
				this.$get("/api/vip/draw.html", {
					username: this.searchdata.user_name
				}).then(res => {
					if (res.data.code == 0) {
						this.isGetPrize = true;
					}
					this.prizeDialog = true;
					this.$refs.getVoice.play();
				});
			},
			nFormatter(num, digits) {
				const si = [{
						value: 1,
						symbol: ""
					},
					{
						value: 1e3,
						symbol: "K"
					},
					{
						value: 1e6,
						symbol: "M"
					}
					// { value: 1e9, symbol: "G" },
					// { value: 1e12, symbol: "T" },
					// { value: 1e15, symbol: "P" },
					// { value: 1e18, symbol: "E" }
				];
				const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
				let i;
				for (i = si.length - 1; i > 0; i--) {
					if (num >= si[i].value) {
						break;
					}
				}
				return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
			},
			changeJhe(item) {
				location.href = item.value
			},
			changeLaguages(item) {
				this.$i18n.locale = item.value;
				this.selLanguage = item;
				localStorage.setItem("language", item.value);
				this.$router.replace("/");
				location.reload();
			},
			//样式切换
			changestyle(index) {
				this.activeIndex = index;
			},
			//搜索
			search(user) {
				//请求
				let params = {} 
				if (this.nowLang == 'usdt') {
					params = {
						username: user ? user : this.username,
						currency: "USDT"
					}
				} else if(this.nowLang == 'id') {
					params = {
						username: user ? user : this.username,
						currency: "IDR"
					}
				}else {
					params = {
						username: user ? user : this.username,
					}
				}
				
				this.$get("/api/vip/query.html", params)
					.then(res => {
						this.isQueryDia = true;
						if (res.data.code == 0) {
							this.searchdata = res.data.data;
							this.searchStatus = true;
							// this.searchdata.promotion_list = [
							//   {
							//     name: "月月负利",
							//     money: 885,
							//     type: 4
							//   },
							//   {
							//     name: "月月负利6666",
							//     money: 885,
							//     type: 6
							//   }
							// ];
							if (this.searchdata.promotion_list && this.searchdata.promotion_list.length > 0) {
								this.searchdata.promotion_list.forEach(item => {
									item.typeName = this.promotionType[item.type];
								});
							}
						} else {
							this.searchStatus = false;
						}
					})
					.catch(r => {});
			},
			//会员优势
			getList1() {
				this.$get("/api/vip/level.html", {
					type: 1
				}).then(res => {
					if (res.data.code == 0) {
						this.dataList1 = res.data.data;
						this.dataList1.forEach(element => {
							element.a_drop_trans = this.nFormatter(element.a_keep, 2);
						});
						
					}
				});
			},
			// getList1() {
			// 	this.$get("/api/vip/level.html", {
			// 		type: 1
			// 	}).then(res => {
			// 		if (res.data.code == 0) {
			// 			res.data.data.forEach(element => {
			// 				element.a_drop_trans = this.nFormatter(element.a_drop, 2);
			// 			});
			// 			this.dataList1 = res.data.data;
			// 		}
			// 	});
			// },
			//好运彩金
			getList2() {
				this.$get("/api/vip/level.html", {
					type: 2
				}).then(res => {
					if (res.data.code == 0) {
						res.data.data.forEach(element => {
							element.m_drop_trans = this.nFormatter(element.m_drop, 2);
						});
						this.dataList2 = res.data.data;
					}
				});
			},
			//月存豪礼
			getList3() {
				this.$get("/api/vip/level.html", {
					type: 3
				}).then(res => {
					if (res.data.code == 0) {
						this.dataList3 = res.data.data;
					}
				});
			},
			//月存豪礼
			getList4() {
				this.$get("/api/vip/level.html", {
					currency:'USDT'
				}).then(res => {
					if (res.data.code == 0) {
						this.usdtAdvantages = res.data.data;
					}
				});
			},
			//网站信息
			getWebInfo() {
				this.$get("/api/sysconf.html").then(res => {
					if (res.data.code == 0) {
						this.footData = res.data.data;
						this.whatsapp = res.data.data.whatsapp_cs_url;
						this.n = Math.floor(Math.random() * this.whatsapp.length + 1)-1;
						this.whatsapp_cs_url = this.whatsapp[this.n]
						
						document.title = this.footData.site_name;
						this.siteName = this.footData.site_name;
						this.pcLogoUrl = this.footData.pc_logo;
						this.mobileLogoUrl = this.footData.mobile_logo;
						this.customBeginDate = this.footData.custom_begin_date;
						this.templayteName = this.footData.custom_template_name
						var regEx = new RegExp("\\-", "gi");
						// if (this.nowLang != "zh") {
						// 	this.customBeginDate = this.customBeginDate.replace(regEx, "/");
						// 	console.log(this.customBeginDate);
						// }
					}
				});
			},

			//语言切换
			languageChange(lang) {
				this.$i18n.locale = lang;
				localStorage.setItem("language", lang);
				location.reload();
			}
		},
		watch: {
			dataList1: function() {
				this.$nextTick(function() {
					let tableBox = this.$refs.tableBox;
					let tableBoxFather = this.$refs.tableBoxFather;
					tableBoxFather.style.height = tableBox.clientHeight * 0.67 + "px";
				});
			},
			usdtAdvantages: function() {
				this.$nextTick(function() {
					let tableBox = this.$refs.tableBox;
					let tableBoxFather = this.$refs.tableBoxFather;
					tableBoxFather.style.height = tableBox.clientHeight * 0.67 + "px";
				});
			}
		}
	};
</script>
<style scoped lang="scss">
	.homepage {
		.logo {
			background-color: #e6eefe;
			padding-left: 20px;

			.logo-img {
				width: 10rem;
				height: auto;
				position: relative;
			}

			.langBox {
				position: absolute;
				right: 20px;
				top: 20px;

				span {}

				img {
					width: 20px;
					height: 14px;
					margin-right: 5px;
				}

				.selLang {
					cursor: pointer;
				}
			}
		}

		//tabbar
		.tabBar {
			display: flex;
			justify-content: space-between;
			padding: .6rem 6px;
			.img-logo {
				width: 30%;
			}
			.el-icon-s-unfold {
				color: #fff;
				font-size: 30px;
			}

			a {
				color: #fff;
				text-decoration: none;
			}

			#positonBox {
				position: relative;
			}

			div {
				height: 2rem;
				color: #fff;
				// text-align: center;
				flex: auto;
				line-height: 2rem;
				font-size: 12px;
				&.tr {
					text-align: right;
				}
				span {
					display: inline-block;
					line-height: 30px;
				}

				.click {
					border-bottom: 1px solid #fffe00;
					font-size: 14px;
				}

				.icon {
					position: absolute;
					top: 3px;
					right: 2px;
					display: inline-block;
					width: 10px;
					height: 10px;
					background-image: url(../assets/mobileimages/line.png);
					background-size: 100%;
				}
			}
		}

		.list-content {
			display: flex;
		}

		.img-prize,
		.img-prize2 {
			flex: 1;
			min-width: 166px;
			right: 5px;
			z-index: 100;
			height: 120px;
			line-height: 1;

			.wrap {
				width: 150%;
				height: 150%;
				transform: scale(0.665);
				transform-origin: 0 0;
			}

			.prize-title {
				position: absolute;
				width: 80%;
				top: 20%;
				left: 10%;

				span {
					width: 30%;
					text-align: left;
					font-weight: 700;
					color: #1056a4;
					font-size: 12px;
				}

				span:first-child {
					width: 40%;
					padding-left: 3px;
					box-sizing: border-box;
				}

				span:last-child {
					width: 30%;
					padding-left: 3px;
					box-sizing: border-box;
				}
			}

			.review_box {
				position: absolute;
				// background-color: red;
				width: 80%;
				height: 58%;
				bottom: 10.6%;
				left: 10%;
				overflow: hidden;

				ul {
					list-style: none;

					li {
						font-size: 12px;
						margin-top: 10px;
						color: #efbc50;

						span {
							width: 30%;
							text-align: left;
						}

						span:first-child {
							width: 40%;
							padding-left: 3px;
							box-sizing: border-box;
						}

						span:last-child {
							width: 30%;
							padding-left: 3px;
							box-sizing: border-box;
						}
					}
				}
			}
		}

		.img-prize {
			top: 1%;
			background: url(../assets/images1/game_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_yn {
			background: url(../assets/images1/game_pc_yn.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_en {
			background: url(../assets/images1/game_pc_en.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_th {
			background: url(../assets/images1/game_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_zh {
			background: url(../assets/images1/game_pc_zh.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize_id {
			background: url(../assets/images1/game_pc_id.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2 {
			top: 50%;
			background: url(../assets/images1/bonus_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_yn {
			background: url(../assets/images1/bonus_pc_yn.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_en {
			background: url(../assets/images1/bonus_pc_en.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_th {
			background: url(../assets/images1/bonus_pc_th.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_zh {
			background: url(../assets/images1/bonus_pc_zh.png) no-repeat;
			background-size: 100% 100%;
		}

		.img-prize2_id {
			background: url(../assets/images1/bonus_pc_id.png) no-repeat;
			background-size: 100% 100%;
		}

		//大图
		.bigPic {
			height: 150px;
			position: relative;
			border-top: 2px solid #0040a3;
			border-bottom: 2px solid #0040a3;
			background: url("../assets/images/banner.png") no-repeat;
			background-size: 100% 100%;
			img:first-child {
				width: 100%;
				height: 100%;
			}
			.banner-info {
				width: 61%;
				margin-left: 35%;
				padding-top: 2rem;
				text-align: center;
				h2 {
					font-family: Kanit-Bold;
					font-size: 1.8rem;
					color: #f0df1d;
				}
				div {
					margin-top: 1rem;
					font-family: Kanit-Regular;
					font-size: 1rem;
					color: #ffffff;
				}
			}
		}

		//search
		.search {
			padding: 0px 10px;
			display: flex;
			height: 60px;
			justify-content: center;
			align-items: center;

			div {
				display: inline-block;
				font-size: 12px;
				color: #be0004;
			}

			.searchInput {
				padding: 0 6px;

				.text {
					font-size: 12px;
					// margin-left: 9px;
					font-weight: bold;
					color: #fff;
					margin-right: 7px;
				}

				// width: 280px;
				width: 100%;
				height: 28px;
				background-color: #b73b40;
				border-radius: 15px;
				background-image: linear-gradient(#1474cd, #10509a, #0b2b68);
				display: flex;
				align-items: center;
				justify-content: center;

				input {
					width: 122px;
					height: 22px;
					margin-left: 2px;
					border: 0;
					border-radius: 15px;
					outline: none;
					text-indent: 6px;
					font-size: 12px;
					background-color: #fff;
					color: #000;
					flex: 1;

					&::-webkit-input-placeholder {
						color: #999;
					}

					&:focus::-webkit-input-placeholder {
						opacity: 0;
					}
				}

				span {
					// margin-right: 10px;
					display: inline-block;
					margin-left: 15px;
					// width: 47px;
					padding: 0 10px;
					height: 23px;
					color: #000;
					text-align: center;
					font-size: 12px;
					line-height: 23px;
					background-image: linear-gradient(0deg, #daa23e 11%, #8e541c 25%, #daa23e 38%, #ffcf5e 45%, #fff9e8 100%), linear-gradient(#122342, #122342);
					border-radius: 10px;
				}
			}
		}

		// tabbar
		.changeStyle {
			margin: 0 10px;
			div {
				width: 50%;
				text-align: center;
				font-size: 0.8rem;
				color: #fff;
				line-height: 1;
				height: 40px;
				padding: 8px 6px;
				box-sizing: border-box;
				background-size: 100% 100%;
				word-wrap: break-word;

				p {
					position: relative;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				&:first-child {
					background-image: url(../assets/mobileimages/new_a1.png);
					background-repeat: no-repeat;
					background-position: center;

					&.active {
						background-image: url(../assets/mobileimages/new_a2.png);
						background-repeat: no-repeat;
						background-position: center;
						color: #000;
						font-weight: 700;
						font-size: 0.9rem;
					}
				}

				// &:nth-child(2) {
				//   background-image: url(../assets/mobileimages/b1.png);
				//   background-repeat: no-repeat;
				//   background-position: center;
				//   &.active {
				//     background-image: url(../assets/mobileimages/b2.png);
				//     background-repeat: no-repeat;
				//     background-position: center;
				//     color: #000;
				//     font-weight: 700;
				//   }
				// }
				// &:nth-child(3) {
				//   background-image: url(../assets/mobileimages/b1.png);
				//   background-repeat: no-repeat;
				//   background-position: center;
				//   &.active {
				//     background-image: url(../assets/mobileimages/b2.png);
				//     background-repeat: no-repeat;
				//     background-position: center;
				//     color: #000;
				//     font-weight: 700;
				//   }
				// }
				&:last-child {
					background-image: url(../assets/mobileimages/new_c1.png);
					background-repeat: no-repeat;
					background-position: center;

					&.active {
						background-image: url(../assets/mobileimages/new_c2.png);
						background-repeat: no-repeat;
						background-position: center;
						color: #000;
						font-weight: 700;
						font-size: 0.9rem;
					}
				}
			}
		}

		// .itemHeader {
		// 	margin: 0 10px;
		// 	height: 30px;
		// 	background-repeat: no-repeat;
		// 	background-position: bottom;
		// }

		// .itemFooter {
		// 	margin: 0 10px;
		// 	height: 30px;
		// 	background-repeat: no-repeat;
		// 	background-position: top;
		// }

		.itemInfo {
			padding: 3rem 3% 4rem;
			background: url(../assets/mobileimages/table_bg.png) no-repeat;
			background-size: 100% 100%;
			overflow: hidden;
			color: #fff;

			.none {
				display: none;
			}

			.textBox {
				margin-top: 1.5rem;
				margin-bottom: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;

				span {
					display: inline-block;
					width: 10rem;
					height: 1.5rem;
					background-image: url(../assets/mobileimages/text.png);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					text-align: center;
					color: #fff;
					font-size: 13px;
					line-height: 1.5rem;
				}
			}

			.content {
				margin: 0 10px;
				margin-bottom: 10px;
				font-size: 14px;
				line-height: 1.4rem;
			}

			.redText {
				margin: 0 10px;
				font-size: 13px;
			}

			.tip {
				margin-top: 10px;
				color: #f0df1d;
				font-size: 13px;
				text-align: center;
			}

			.tableFather {
				height: 844px;

				.tableBox {
					width: 150%;
					border: 1px solid #d5ccbe;
					transform: scale(0.665);
					-webkit-transform: scale(0.665);
					-moz-transform: scale(0.665);
					text-align: center;
					transform-origin: 0 0;
				}

				.tableTh {
					display: flex;
					background-color: rgba($color: #0600ff, $alpha: .17);
					color: #f0df1d;

					div {
						display: flex;
						width: 10%;
						padding: 5px 0;
						flex: 1;
						font-size: 12px;
						text-align: center;
						color: #fff;
						justify-content: center;
						align-items: center;
						word-break: break-word;
						border-right: 1px solid #d5ccbe;
						border-bottom: 1px solid #d5ccbe;

						&:last-child {
							border-right: 0;
						}
					}
				}

				.tableTd {
					height: 40px;
					display: flex;

					div {
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 1;
						text-align: center;
						font-size: 12px;
						word-break: break-word;
						border-right: 1px solid #d5ccbe;
						border-bottom: 1px solid #d5ccbe;
					}
				}

				margin: 0 10px;
				overflow: hidden;

				.tableBox {
					color: #fff;
					.tableTh {
						th {
							width: 10%;
							font-size: 12px;
							text-align: center;
							background-color: #d3b89e;
						}
					}

					.tableTd {
						.correct:before {
							// content: "\2714";
							content: '\2714\fe0e';
							color: #fff;
						}

						.incorrect:before {
							// content: "\2716";
							content: '\2716\fe0e';
							color: #fff;
						}
						
						td {
							line-height: 1rem;
							padding: 5px 0;
							color: #000;
							font-size: 12px;
							text-align: center;
							background-color: #e1dfdf;
						}
					}
				}
			}

			//彩金
			.handselBox {
				padding: 1rem 11px 2rem;

				.handsel1 {
					margin-bottom: 9px;
					font-size: 14px;
					font-weight: bold;
					line-height: 16px;
					color: #f00;
				}

				.handsel2 {
					p {
						font-size: 14px;
						line-height: 16px;
					}

					margin-bottom: 16px;
				}

				.handsel3 {
					margin-bottom: 7px;
					font-size: 13px;
					line-height: 16px;
				}

				.handsel4 {
					font-size: 13px;
					color: #f00;
					margin-bottom: 12px;
				}
			}

			.headselTable {
				padding: 0 11px;
				margin-bottom: 15px;

				.headseltableBox {
					width: 100%;
					border: 1px solid #d5ccbe;

					.headseltableTh {
						display: flex;
						text-align: center;
						border-bottom: 1px solid #fff;
						background-color: rgba($color: #0600ff, $alpha: .17);
						color: #f0df1d;
						font-size: 12px;
						line-height: 14px;

						div {
							display: flex;
							flex: 1;
							justify-content: center;
							align-items: center;
							word-break: break-word;
							padding: 5px 0;
							border-right: 1px solid #fff;

							&:last-child {
								border-right: 0;
							}
						}
					}

					.headseltableTd {
						display: flex;

						div {
							display: flex;
							flex: 1;
							height: 30px;
							line-height: 30px;
							text-align: center;
							font-size: 12px;
							justify-content: center;
							align-items: center;
							word-break: break-word;
							border-right: 1px solid #d5ccbe;
							border-bottom: 1px solid #d5ccbe;

							&:last-child {
								border-right: 0;
							}
						}
					}
				}

				th {
					height: 21px;
					line-height: 21px;
					font-size: 13px;
					color: #fff;
					background-image: linear-gradient(#2883f7, #0a27a0);
				}

				td {
					height: 23px;
					line-height: 23px;
					font-size: 13px;
					text-align: center;
					background-color: #e1dfdf;
				}
			}

			//礼品
			.giftBox {
				padding: 0 10px;

				.giftTit {
					color: #f00;
					font-size: 16px;
					line-height: 13px;
					margin-bottom: 7px;
				}

				.giftCon {
					font-size: 13px;
					line-height: 18px;
					margin-bottom: 11px;
				}

				.giftTable {
					width: 100%;
					border: 1px solid #d5ccbe;

					.giftTableTh {
						display: flex;
						text-align: center;
						background-color: #d3b89e;
						color: #fff;
						font-size: 13px;
						height: 30px;
						line-height: 30px;

						div {
							flex: 1;
							border-right: 1px solid #fff;

							&:last-child {
								border-right: 0;
							}
						}
					}

					.giftTableTd {
						display: flex;

						div {
							display: flex;
							flex: 1;
							height: 30px;
							line-height: 30px;
							text-align: center;
							font-size: 12px;
							justify-content: center;
							align-items: center;
							word-break: break-word;
							border-right: 1px solid #d5ccbe;
							border-bottom: 1px solid #d5ccbe;

							&:last-child {
								border-right: 0;
							}
						}
					}

					th {
						font-size: 12px;
						line-height: 22px;
						background-image: linear-gradient(#2883f7, #0a27a0);
						color: #fff;
					}

					td {
						background-color: #e1dfdf;
						font-size: 12px;
						text-align: center;
						line-height: 21px;
					}
				}
			}

			//豪礼
			.privilegeBox {
				padding: 0 27px 0 10px;

				.privilegeTit {
					color: #f00;
					font-size: 14px;
					line-height: 13px;
					margin-bottom: 7px;
					font-weight: bold;
				}

				.privilegeCon {
					font-size: 12px;
					padding-bottom: 15px;

					span {
						color: #f00;
					}
				}
			}
		}

		//弹窗
		.cover {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			z-index: 100;
		}

		.dialog-query {
			position: fixed;
			z-index: 200;
			width: 90%;
			background-color: #fff;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 5px;
			font-size: 22px;
			font-weight: bold;

			.dia-top {
				width: 100%;
				height: 50px;
				background-color: #206fe1;
				color: #fff;
				font-size: 22px;
				border-radius: 5px 5px 0 0;
				position: relative;

				.t-queryresult {
					text-align: center;
					width: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}

				img {
					width: 22px;
					height: 22px;
					position: absolute;
					top: 5px;
					right: 5px;
					// top: 50%;
					// right: 30px;
					// transform: translateY(-50%);
					cursor: pointer;
				}

				.incorrect:before {
					content: "\2716";
					color: #ff0000;
					position: absolute;
					top: 50%;
					right: 30px;
					transform: translateY(-50%);
					cursor: pointer;
					font-size: 18px;
				}
			}

			.dialog-main {
				.dialog-top {
					div {
						text-align: center;
						font-size: 14px;
						line-height: 14px;
						font-weight: bold;

						&:nth-child(1) {
							margin-top: 10px;
							margin-bottom: 6px;
						}

						&:nth-child(2) {
							margin-bottom: 6px;
						}

						&:nth-child(3) {
							margin-bottom: 9px;
						}

						span {
							color: #c81525;
							font-weight: normal;
						}
					}
				}

				.dialog-vip {
					line-height: 13px;
					text-align: center;
					margin-bottom: 10px;

					.box {
						font-size: 30px;
						line-height: 36px;
						display: inline-block;
						font-weight: bolder;
						background-image: -webkit-linear-gradient(#f49e23, #eaea34, #f49e23);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						border: #000000;
					}
				}

				.get-wrap {
					width: 100%;

					.btn-get {
						margin: 0 auto;
						margin-bottom: 10px;
						height: 30px;
						padding: 0 5px;
						background-color: #fff;
						border: 1px solid red;
						color: red;
						font-weight: 700;
						border-radius: 4px;
						vertical-align: top;
						cursor: pointer;
						outline: 0;
					}

					.btn-get.noget {
						background-color: #fff;
						border: 1px solid #ddd;
						color: #ddd;
						cursor: not-allowed;
					}
				}

				.dialog-list {
					margin-top: 4px;
					margin: 0 10px;
					border: 1px solid #d8d8d8;

					&:not(:first-child) {
						border-bottom: 0;
						color: #f00;
					}

					.dialog-item {
						display: flex;
						align-items: center;

						border-bottom: 1px solid #d8d8d8;

						&:first-child {}

						div {
							padding: 3px 6px;
							width: 100%;
							text-align: center;
							font-size: 13px;

							&:first-child {
								color: #717171;
								border-right: 1px solid #d8d8d8;
							}

							&:last-child {
								color: #c81525;
								height: 100%;
							}
						}

						.correct:before {
							content: "\2714";
							color: #008100;
						}

						.incorrect:before {
							content: "\2716";
							color: #b20610;
						}
					}
				}

				.dialog-text {
					margin-top: 15px;
					margin-bottom: 20px;

					div {
						text-align: center;
						font-size: 16px;
						line-height: 16px;
						color: #0b065d;
						margin-top: 12px;
					}
				}
			}

			.dislog-close {
				// position: fixed;
				width: 100%;
				// bottom: 15px;
				margin-bottom: 15px;
				text-align: center;

				span {
					display: inline-block;
					width: 148px;
					height: 30px;
					background-color: #206fe1;
					border-radius: 20px;
					color: #fff;
					font-size: 14px;
					line-height: 30px;
				}
			}

			.dialog-cover {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 700;
				background-color: rgba(0, 0, 0, 0.5);
			}

			.dialog-get {
				position: absolute;
				background: url(../assets/images/img_red.png) no-repeat;
				background-size: 100% 100%;
				min-height: 345px;
				width: 80%;
				// background-color: #fff;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -60%);
				z-index: 800;
				border-radius: 3px;
				color: #fff;

				.p1 {
					font-size: 15px;
					font-weight: 700;
					// margin-top: 20px;
					margin-top: 61%;
					margin-bottom: 10px;
				}

				.p2 {
					font-weight: 500;
					font-size: 13px;
					margin-top: 8px;

					.money {
						font-size: 22px;
						font-weight: 700;
						color: #ffcf5e;
					}
				}

				.btn-close {
					width: 84%;
					height: 60px;
					margin-bottom: 20px;
					background-color: #1474cd;
					border-radius: 2px;
					margin-top: 26px;
					cursor: pointer;
					background: url(../assets/images/img_concern.png) no-repeat center center;
					background-size: 100% 100%;
					color: rgb(59, 59, 59);
					font-weight: 700;
					text-align: center;
					padding-top: 11px;
					box-sizing: border-box;
					font-size: 17px;
				}
			}
		}

		//底部
		.footer {
			height: 40px;
			display: flex;
			margin-top: 1rem;
			justify-content: center;
			align-items: center;
			color: #4e4e4e;
			font-size: 12px;
			background-color: #1a1a1a;
		}
	}
</style>
